<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div v-if="this.$route.query.token" >
      <div class="auth-inner py-2">
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <h1 class="brand-text text-primary ml-1">
              Security Assessment Results
            </h1>
          </b-link>
          <b-card-text class="mb-2">
            <process phase="Assessment" />
          </b-card-text>
          <b-row>
            <b-col cols="12" class="text-center">
              <h2>Your Security Strength is LOW</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <vue-apex-charts
                type="radialBar"
                height="200"
                class="my-2"
                :options="goalOverviewRadialBar"
                :series="[45]"
              />
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col
              v-for="item in ['configurations', 'identities', 'resources']"
              :key="item"
              xl="4"
              sm="12"
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="primary"
                  >
                    <!-- <feather-icon
                      size="24"
                      :icon="item.icon"
                    /> -->
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ Object.keys(stats[item]).length }}
                  </h4>
                  <b-card-text class="mb-0">
                    {{ item }} in risk
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
          <b-card-text class="mb-2">
            <b-tabs
              content-class="mt-2"
              justified
            >
              <b-tab
                title="Tests"
              >
                <div v-for="scenario in scenarios" :key="scenario.title" class="border-bottom mb-2">
                  <div>
                    <b-badge
                      :variant="`light-primary`"
                      class="mr-1"
                    >
                      {{ scenario.priority }}
                    </b-badge>
                  </div>
                  <div class="mt-1">
                    <h4 class="d-inline mr-2">{{ scenario.title }}</h4>
                    <b-badge
                      v-if="scenario.is_premium"
                      pill
                      variant="light-dark"
                      class="text-capitalize"
                    >
                      Upgrade to premium
                    </b-badge>
                    <b-badge
                      v-else-if="scenario.failed"
                      pill
                      variant="light-danger"
                      class="text-capitalize"
                    >
                      Failed: {{ scenario.error }}
                    </b-badge>
                    <b-badge
                      v-else
                      pill
                      variant="light-success"
                      class="text-capitalize"
                    >
                      Passed: {{ scenario.success }}
                    </b-badge>
                  </div>
                  <div>
                  </div>
                  <p>
                    {{ scenario.overview }}
                  </p>
                </div>
              </b-tab>
              <b-tab
                title="Risks"
              >
                <b-table
                  :items="items"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  :fields="['id', 'risks']"
                  :current-page="currentPage"
                >
                  <template #cell(risks)="data">
                    <div v-for="scenario in data.item.scenarios" :key="scenario.id">
                      {{ scenario.error }}
                    </div>
                  </template>
                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        aria-controls="scenarios-table"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        v-model="currentPage"
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </b-card-text>
          <b-card-text class="text-center">
            <div><b-link :to="`/strategy?token=${this.$route.query.token}`" class="btn btn-primary mr-1">Build Security Strategy</b-link></div>
          </b-card-text>
        </b-card>
      </div>
    </div>
    <div v-else-if="!this.$route.query.code">
      <a v-if="gw_auth_link"
        class="btn btn-outline-primary"
        :href="gw_auth_link"
        >
        <img
          height="36"
          src="@/assets/images/logos/Google Workspace.webp"
        >
        Login with Google Workspace
      </a>
      <span v-else>loading...</span>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BRow, BCol, BTable, BBadge, BMedia, BTab, BTabs,
  BPagination, BAvatar, BMediaBody, BCardText, BMediaAside, BLink,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Process from './Process.vue'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#ea5455'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    Process,
    BTable,
    BMediaBody,
    BCardText,
    BAvatar,
    BPagination,
    BBadge,
    BMediaAside,
    BTab,
    BTabs,
    BMedia,
    BLink,
    VueApexCharts,
  },
  data() {
    return {
      sso: null,
      gw_auth_link: '',
      scenarios: [],
      perPage: 5,
      scenariosPerPage: 5,
      stats: {},
      currentPage: 1,
      scenariosCurrentPage: 1,
      tableColumns: ['id', 'name', 'risks', 'show_details'],
      items: [],
      goalOverviewRadialBar: {
        chart: {
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.5rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.danger],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  computed: {
    totalRows() {
      return this.items.length
    },
    scenariosTotalRows() {
      return this.scenarios.length
    },
  },
  methods: {
    getResults() {
      useJwt.getResults({
        token: this.token,
      }).then(response => {
        this.stats = response.data.stats
        this.scenarios = response.data.scenarios
        this.items = Object.values(this.stats.identities).concat(Object.values(this.stats.configurations), Object.values(this.stats.resources))
      })
    },
  },
  mounted() {
    this.sso = this.$route.query.code
    this.token = this.$route.query.token
    if (this.token) {
      // setInterval(this.getResults, 5000)
      this.getResults()
    } else if (this.sso) {
      useJwt.addLogin2({
        type: 'sso',
        user: 'root',
        app_name: 'Google Workspace',
        details: {
          code: this.$route.query,
        },
      }).then(response => {
        this.$router.push(`results?token=${response.data.token}`)
        this.token = this.$route.query.token
        setInterval(this.getResults, 5000)
      })
    } else {
      useJwt.getGWLink({}).then(response => {
        localStorage.setItem('gwFlow', JSON.stringify(response.data))
        this.gw_auth_link = response.data
      })
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
